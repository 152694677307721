import * as React from 'react';
import { Fragment } from 'react';
import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  Pagination,
  ReferenceArrayField,
  ReferenceInput,
  TextField,
  TopToolbar,
  useDataProvider,
  useListContext,
} from 'react-admin';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DatetimeField from 'src/components/DatetimeField';
import DebouncedTextInput from 'src/components/DebouncedTextInput';
import { EditAttributes } from '@mui/icons-material';
import { DatagridBulkActionDialog } from '../../components/DatagridBulkActionDialog';
import { TableCell, TableRow } from '@mui/material';

type DocRecordType = 'book' | 'pdf' | 'html' | 'pubcom' | 'guideline' | 'law';
export type DocRecord = {
  // 抜粋...
  id: string;
  type: DocRecordType;
  title: string;
  authors: string[] | null;
  publisher?: string;
  packIds: string[];
  planIds?: string[];
  availableUntil?: string;
  availableFrom?: string;
  purchaseType?: number;
  reservableFrom?: string;
};

export const DocIcon = MenuBookIcon;

const ListActions: React.FC = () => <TopToolbar />;
const filters = [
  <DebouncedTextInput source="id" alwaysOn multiline label="ID (複数行入力可)" />,
  <DebouncedTextInput source="title" alwaysOn />,
  <DebouncedTextInput source="authors" alwaysOn />,
  <ReferenceInput source="publisher" reference="publishers" alwaysOn>
    <AutocompleteInput optionText="name" optionValue="name" label="resources.publishers.name" sx={{ width: 300 }} />
  </ReferenceInput>,
];

const TableColumnRow: React.FC = () => {
  return (
    <TableRow>
      <TableCell component="th">ID</TableCell>
      <TableCell component="th">タイトル</TableCell>
      <TableCell component="th">出版社</TableCell>
    </TableRow>
  );
};

const TableRecordRow: React.FC<{ record: DocRecord }> = (props) => {
  return (
    <TableRow>
      <TableCell key="isbn" component="th" scope="row">
        {props.record.id}
      </TableCell>
      <TableCell key="title">{props.record.title}</TableCell>
      <TableCell key="publisher">{props.record.publisher}</TableCell>
    </TableRow>
  );
};

const BulkActionDialog: React.FC = () => {
  const dataProvider = useDataProvider();

  const resource = 'docs';
  const path = `${resource}/packs/bulk`;
  const body = { docIds: useListContext().selectedIds, packIds: ['1'] };

  return (
    <DatagridBulkActionDialog
      resource={resource}
      label="基本パック追加"
      icon={<EditAttributes />}
      callApi={async () => {
        return await dataProvider.invoke(path, body);
      }}
      TableColumnRow={TableColumnRow}
      TableRow={TableRecordRow}
    />
  );
};

const BulkActionButtons: React.FC = () => {
  return (
    <Fragment>
      <BulkActionDialog />
    </Fragment>
  );
};

export const DocList: React.FC = () => {
  return (
    <List
      actions={<ListActions />}
      filters={filters}
      perPage={1000}
      pagination={<Pagination rowsPerPageOptions={[10, 100, 1000]} />}
    >
      <Datagrid bulkActionButtons={<BulkActionButtons />} rowClick="show">
        <TextField source="id" sortable={true} />
        <TextField source="title" sortable={false} />
        <FunctionField
          source="authors"
          sortable={false}
          render={(record?: { authors: string[] }) => {
            return record?.authors?.join('、');
          }}
        />
        <TextField source="publisher" sortable={false} />
        <ReferenceArrayField source="packIds" reference="packs" sortable={false} />
        <DatetimeField source="availableFrom" sortable={true} />
        <DatetimeField source="availableUntil" sortable={true} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export * from './show';
export * from './update';
