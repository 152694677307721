import * as React from 'react';
import { useListContext, useNotify, useUnselectAll } from 'react-admin';
import { Fragment } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';

type DatagridBulkActionDialogProps = {
  resource: string;
  icon: React.ReactNode;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callApi: () => Promise<{ data: any }>;
  TableColumnRow: React.FC;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TableRow: React.FC<{ record: any }>;
};

/***
 * List 内のアイテムを選択して、一括処理を行うためのダイアログを表示するコンポーネント
 * @param props
 * @see https://marmelab.com/react-admin/Datagrid.html#bulkactionbuttons
 */
export const DatagridBulkActionDialog: React.FC<DatagridBulkActionDialogProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const { data, selectedIds } = useListContext();
  const notify = useNotify();
  const unselectAll = useUnselectAll(props.resource);

  const handleOpen = () => {
    if (data.length > 0) {
      setOpen(true);
    } else {
      notify('対象のデータを選択してください', { type: 'warning' });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const startRequest = async () => {
    try {
      const response = await props.callApi();
      notify(`${selectedIds.length} 件中 ${response.data?.length} 件について、${props.label}処理をしました。`, {
        type: 'info',
      });
    } catch (error) {
      notify(`Error: ${error.message}`, { type: 'error' });
    }
    unselectAll();
    handleClose();
  };

  const selectedData = selectedIds.map((id) => data.find((datum) => datum.id === id));

  return (
    <Fragment>
      <Button onClick={handleOpen} color="primary" size="small" startIcon={props.icon}>
        {props.label}
      </Button>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>{props.label}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" gutterBottom>
              以下の選択された <b>{selectedData.length} 件</b>のアイテムにつき<b>{props.label}</b>
              を開始します、よろしいですか？
            </Typography>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <props.TableColumnRow />
                </TableHead>
                <TableBody>
                  {selectedData.map((datum) => (
                    <props.TableRow record={datum} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={startRequest} color="primary">
            {props.label}を開始する
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
